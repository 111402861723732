@import 'variables';

.header {
  text-align: center;
  padding: 0.75em;
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  @include border-bottom
}

.toggleFriends {
  cursor: pointer;
  font-size: x-large;
}

.title {
  cursor: pointer;
  font-size: x-large;
}
