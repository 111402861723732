.betSelection {
  display: flex;
  justify-content: center;
  margin-bottom: 1em !important;
}

.stats {
  padding-top: 3rem;
  padding-bottom: 2rem;
}
