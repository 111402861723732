@import "variables";

.menu {
  padding: 1rem;
  height: 100%;
  background-color: var(--bs-body-bg);
}

.wheel {
  display: flex;
  justify-content: center;
}