.features {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.entry {
  padding-top: 3rem;
  align-items: center;

}
