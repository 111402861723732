@import 'variables';

.list {
  list-style: none;
}

.onlineStatus {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 0.25em;

  &_online {
    @include background-color("success");
  }

  &_offline {
    @include background-color("danger");
  }
}

.friend {
  margin-left: 1em;
}
