@import 'variables';

.chat {
  background-color: var(--bs-body-bg);
  position: fixed;
  top: 0;
  height: 100vh;
  right: 0;
  width: $chat-width;
  display: flex;
  flex-direction: column;
  @include border-left;
  z-index: $zindex-dropdown - 1;
}

@include media-breakpoint-down(md) {
  .chat {
    width: 100vw;
    max-width:100%;
  }
}
