@import "variables";

.jumbotron {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  color: $white;
}

.info {
  font-size: 0.75rem;
  color: $gray-300;
  margin-top: 0.25rem;
}

.overview {
  background-color: $dark;
}
